import styled from 'styled-components'

const StyledPagePropos = styled.section`
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

        @media screen and (max-width: 1416px) {
            .container-profil {
                width: 100% !important;
                padding: 0 24px !important;
            }
            .wrapper-titleh3 {
                width: 100% !important;
                padding: 0 24px !important;
            }

            .separator.one p {
                width: 100% !important;
                padding: 0 96px !important;
            }
            .separator.two p {
                width: 100% !important;
                padding: 0 96px !important;
            }

            .container-txt-propos {
                width: 97% !important;
            }
            .wrapper-txt {
                width: 67% !important;
            }

            .container-coord {
                width: 97% !important;
            }
        }

        @media screen and (max-width: 1000px) {
            .separator p {
                font-size: 26px !important;
                line-height: 31px !important;
            }
            .separator.one p {
                padding: 0 48px !important;
            }
            .separator.two p {
                padding: 0 48px !important;
            }
            .wrapper-p p {
                font-size: 19px !important;
                line-height: 22px !important;
                padding:
            }
            .container-bellhouse {
                width: 80% !important;
                padding: 48px 24px !important;
            }
            .container-coord .wrapper-txt {
                min-width: 55% !important;
            }
            .container-coord img {
                min-width: 45% !important;
            }
            .container-coord .logo {
                min-width: 0 !important;
            }
        }

        @media screen and (max-width: 750px) {
            .separator p {
                font-size: 22px !important;
                line-height: 24px !important;
            }
            .container-profil {
                flex-direction: column !important;
            }
            .container-profil .wrapper-img {
                height: 50% !important;
                margin-bottom: 24px;
                align-self: center;
            }
            .container-txt-propos .wrapper-txt {
                padding: 4vw !important;
            }
            .container-profil .wrapper-img img {
                width: auto !important;
            }
            .wrapper-p {
                padding: 0 !important;
            }
            .container-profil.duchesne p {
                text-align: left !important;
            }
            .container-profil.duchesne a {
                align-self: flex-start !important;
                text-align: left !important;
            }
            .container-bellhouse {
                width: 94% !important;
                margin: 144px 0 72px 0 !important;
            }
            .container-bellhouse h5 {
                padding: 12px 24px 24px 24px !important;
                font-size: 32px !important;
            }
            .container-bellhouse img {
                position: absolute;
                top: -36px !important;
                left: 50% !important;
                transform: translate(-50%, -50%);
            }
            .container-coord {
                margin-top: 24px !important;
                flex-direction: column-reverse !important;
                height: auto !important;
            }
            .container-coord .wrapper-txt, .container-coord img {
                width: 100% !important;
            }
            .container-coord .wrapper-txt .logo {
                width: 42px !important;
            }
            .container-coord h6 {
                color: white !important;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
            .wrapper-img {
                width: 60% !important;
            }
            .container-txt-propos .wrapper-img img {
                width: 100% !important;
            }
            .container-txt-propos .wrapper-txt {
                margin-top: 240px !important;
                width: 80% !important;
            }
            .container-profil.evanno .wrapper-p {
                padding-top: 24px !important;
                margin-bottom: 48px !important;
                padding-left: 0 !important;
            }
            .container-profil.evanno .wrapper-img {
                margin-top: 48px;
            }
            .container-profil.duchesne .wrapper-img {
                margin-bottom: 48px;
            }
        }
        @media screen and (max-width: 500px) {
            .separator.one p {
                padding: 0 3vw !important;
            }
            .separator.two p {
                padding: 0 3vw !important;
            }
            .container-txt-propos {
                justify-content: center;
            }
            .container-txt-propos .wrapper-txt {
                width: 100% !important;
                margin-top: 300px !important;
            }
        }

        @media screen and (max-width: 420px) {
            .container-profil.evanno .wrapper-img {
                margin-top: 24px !important;
            }
            .wrapper-btn a {
                width: 130px !important;
                height: 32px !important;
            }
            .wrapper-btn {
                bottom: -15px !important;
            }
        }
        @media screen and (max-width: 370px) {
            .container-profil .wrapper-img {
                height: auto !important;
                margin-bottom: 24px;
                width: 98% !important;
                min-width: 200px !important;
            }
            .container-profil .wrapper-img img {
                width: 100% !important;
            }
            .container-profil {
                padding: 0 12px !important;
            }
        }

        .wrapper-titleh3 {
            width: 1440px;
            padding: 0 48px;
        }

        h3 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
            padding: 24px 0 48px 0;
        }

        small {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 29px;
        }

        .container-profil {
            min-height: 392px;
            width: 1440px;
            display: flex;
            padding: 0 48px;
        }
        .container-profil.duchesne {
            flex-direction: row-reverse;
            margin-bottom: 72px;
        }
        .container-profil.duchesne p {
            text-align: right;
        }

        .container-profil.duchesne a {
            color: #000000;
            font-weight: bold;
            text-align: right;
            align-self: flex-end;
            padding: 24px 0;
            font-size: 18px;
        }
        .container-profil .wrapper-img {
            height: 100%;
            min-width: 350px;
            max-height: 350px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .container-profil .wrapper-img img{
            width: 100%;
        }

        .container-profil .wrapper-img h4 {
            position: absolute;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #FFFFFF;
            bottom: 50px;
        }
        .container-profil .wrapper-img small {
            position: absolute;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 23px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #FFFFFF;
            bottom: 20px;
        }

        .container-profil.evanno .wrapper-p {
            padding-left: 48px;
        }
        .container-profil.duchesne .wrapper-p {
            padding-right: 48px;
            display: flex;
            flex-direction: column;
        }
        .wrapper-p p {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 28px;
            color: #000000;
        }

        .separator {
            width: 100vw;
            height: 136px;
            background: linear-gradient(180deg, #8F6A8F 0%, #4E1D4E 100%);
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .separator.one  {
            height: auto;
            padding: 24px 0;
        }
        .separator.two  {
            height: auto;
            padding: 24px 0;
        }
        .separator.one p {
            width: 1103px;
        }
        .separator.two p {
            width: 1248px;
        }
        .separator p {
            font-family: Lato;
            font-style: italic;
            font-weight: bold;
            font-size: 32px;
            line-height: 38px;
            display: flex;
            align-items: center;
            color: #FFFFFF;
        }

        .container-bellhouse {
            width: 856px;
            height: auto;
            background: #F4E1F4;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
            margin: 96px 0 72px 0;
            padding: 48px;
            position: relative;
        }
        .container-bellhouse h5 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 48px;
            line-height: 56px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #000000;
            padding-bottom: 48px;
            justify-content: center;
        }

        .container-bellhouse p {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            color: #000000;
        }
        .container-bellhouse img {
            position: absolute;
            height: 179px;
            top: -66px;
            left: -71px;
        }
        .container-bellhouse a {
            position: absolute;
            right: 112px;
            bottom: -20px;
            width: 141.65px;
            height: 40px;
            background: #FFFFFF;
            text-decoration: none;
            color: black;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            transition: 400ms;
        }

        .container-bellhouse a:hover {
            transform: scale(1.03);
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
            transition: 400ms;
        }

        .container-txt-propos {
            height: auto;
            width: 1296px;
            display: flex;
            position: relative;
            margin: 72px 0;
        }
        .container-txt-propos .wrapper-txt {
            bottom: 0;
            left: 0;
            margin-top: 48px;
            background: #F4E1F4;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
            max-width: 816px;
            z-index: 1;
            padding: 48px;
        }
        .container-txt-propos .wrapper-txt p {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            color: #000000;
        }
        .container-coord .wrapper-txt img {
            height: 72px;
            width: 42px;
            right: 24px;
            top: 24px;
            position: absolute;
        }

        .container-txt-propos .wrapper-img {
            position: absolute;
            top: 0;
            right: 0;
            max-width: 550px;
            height: auto;
            max-height: 100%;
        }
        .container-txt-propos .wrapper-img img {
            max-height: 100%;
        }

        .container-coord {
            height: 360px;
            width: 1296px;
            display: flex;
            align-items: center;
        }

        .container-coord .wrapper-txt {
            min-width: 45.61%;
            height: auto;
            background: #F4E1F4;
            box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
            padding: 24px;
            position: relative;
        }

        .container-coord .wrapper-txt .logo {
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
        }

        .container-coord .wrapper-txt h6 {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 36px;
            line-height: 43px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #000000;
            position: absolute;
            top: -50px;
        }
        .container-coord .wrapper-txt p {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
        }
        .container-coord .wrapper-txt strong {
            font-family: Lato;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 17px;
            color: #000000;
        }
        .container-coord img {
            width: 54.39%;
        }

        .wrapper-btn {
            width: 100%;
            position: absolute;
            bottom: -24px;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }

        .wrapper-btn a {
            background: linear-gradient(180deg, #8F6A8F 0%, #4E1D4E 100%);
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
            cursor: pointer;
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            color: #FFFDF9;
            color: #FFFFFF;
            width: 180px;
            height: 44px;
            transition: 500ms;
            text-decoration: none;
        }

        .wrapper-btn a:hover {
            transform: scale(1.05);
            transition: 500ms;
        }

`

export default StyledPagePropos