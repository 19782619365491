import React, { useContext } from 'react'
import { useStaticQuery, graphql } from "gatsby"

import logo from '../../../static/logo.jpg'
import bellhouse from '../../../static/bellhouse.png'
import paysage from '../../../static/paysage.png'
import home from '../../../static/home.jpg'

import { GlobalStateContext } from '../../utils/GlobalContextProvider';
import StyledPagePropos from './StyledPagePropos';

export const PagePropos = () => {
    const { pagePropos, data } = useStaticQuery(
        graphql`
            query {
                data:datoCmsInformationsPersonnelle {
                    adresse
                    codePostal
                    email
                    nomDeLEntreprise
                    tel
                    phraseCongesNode {
                        childMarkdownRemark {
                            html
                        }
                    }
                    ville
                }
                pagePropos:datoCmsPageAbout {
                    photo1 {
                      fixed(height: 350) {
                        src
                      }
                    }
                    photo2 {
                      fixed(height: 350) {
                        src
                      }
                    }
                    presentation1Node {
                      childMarkdownRemark {
                        html
                      }
                    }
                    presentation2Node {
                      childMarkdownRemark {
                        html
                      }
                    }
                    siteWebPersonnel
                    banniere1Node {
                      childMarkdownRemark {
                        html
                      }
                    }
                    banniere2Node {
                      childMarkdownRemark {
                        html
                      }
                    }
                    encartElizabethBellhouseNode {
                      childMarkdownRemark {
                        html
                      }
                    }
                    encartTexteNode {
                      childMarkdownRemark {
                        html
                      }
                    }
                    lienElizabethBellhouse
                  }
            }
          `)
    const { titlePage } = useContext(GlobalStateContext)

    return (
        <StyledPagePropos>
            <div className='wrapper-titleh3'>
                <h3>{titlePage} / <small>Qui sommes Nous ?</small></h3>
            </div>
            <div className='container-profil evanno'>
                <div className='wrapper-img'>
                    <img alt='profil' src={pagePropos.photo1.fixed.src} />
                    <h4>Géraldine EVANNO</h4>
                    <small>Gérante</small>
                </div> 
                <div className='wrapper-p'>
                    <div className='dangerousHTML' dangerouslySetInnerHTML={{ __html: pagePropos.presentation1Node.childMarkdownRemark.html}}/>
                </div>                      
            </div>
            <div className='container-profil duchesne'>
                <div className='wrapper-img'>
                    <img alt='profil' src={pagePropos.photo2.fixed.src} />
                    <h4>Pierre DUCHESNE</h4>
                    <small>Fondateur</small>
                </div>
                <div className='wrapper-p'>
                    <div className='dangerousHTML' dangerouslySetInnerHTML={{ __html: pagePropos.presentation2Node.childMarkdownRemark.html}} />
                    <a rel='noopener noreferrer' target='_blank' href={pagePropos.siteWebPersonnel}>Site Web : Pierre DUCHESNE</a>
                </div>           
            </div>
            <div className='separator one'>
                <div dangerouslySetInnerHTML={{ __html: pagePropos.banniere1Node.childMarkdownRemark.html}} />
            </div>
            <div className='container-bellhouse'>
                <img alt='elizabeth bellhouse' src={bellhouse} />
                <h5>Hommage à Elizabeth Bellhouse</h5>
                <div className='dangerousHTML' dangerouslySetInnerHTML={{ __html: pagePropos.encartElizabethBellhouseNode.childMarkdownRemark.html}} />
                <a rel='noopener noreferrer' target='_blank' href={pagePropos.lienElizabethBellhouse}>EN SAVOIR PLUS</a>
            </div>
            <div className='separator two'>
                <div dangerouslySetInnerHTML={{ __html: pagePropos.banniere2Node.childMarkdownRemark.html}} />
            </div>
            <div className='container-txt-propos'>
                <div className='wrapper-txt'>
                    <p>
                        <div className='dangerousHTML' dangerouslySetInnerHTML={{ __html: pagePropos.encartTexteNode.childMarkdownRemark.html}} />
                    </p>
                </div>
                <div className='wrapper-img'>
                    <img alt='paysage' src={paysage} />
                </div>
            </div>
            <div className='container-coord'>
                <div className='wrapper-txt'>
                    <h6>Nos Coordonnées</h6>
                    <p>
                        <strong>{data.nomDeLEntreprise}</strong><br/>
                        {data.adresse}<br/>
                        {data.codePostal} {data.ville}<br/>
                        Tél : {data.tel}<br/>
                        E-mail : {data.email}<br/><br/>

                        N° d'identification CEE - SIREN - SIRET : FR 00 - 413 451 923 - 00010<br/>
                        Code APE : 4791 B<br/>
                        Capital fixe : 9.909,19 €<br/>
                        Membre du Centre de Gestion Agréé de St Malo<br/><br/>

                        <strong>Nos Congés</strong><br/>
                        <p dangerouslySetInnerHTML={{ __html: data.phraseCongesNode.childMarkdownRemark.html}} />
                        <strong>Aucune commande n'est expédiée pendant ces périodes.</strong>
                    </p>
                    <div className='wrapper-btn'>
                        <a rel='noopener noreferrer' target='_blank' href='https://sites.google.com/site/fontvie/Home'>Site Font~Vie</a> 
                        <a rel='noopener noreferrer' target='_blank' href='https://www.blog.fontvie.com/'>Notre Blog</a> 
                    </div>
                    <img className='logo' alt='logo' src={logo} />
                </div>
                <img alt='home' src={home} />
            </div>
        </StyledPagePropos>
    )
}
